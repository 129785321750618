.App {
  background-color: black;
  text-align: center;
  margin: 10%;
  box-shadow: 0px 0px 22px rgb(129, 122, 122);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 2%;
}

.link-underline a {
  text-decoration: underline;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  background-color: rgb(32, 234, 238);
  font-size: 1rem;
  line-height: 1.5;
  padding: 10px 24px;
  border-color: rgb(23, 104, 201);
  min-width: 128px;
  background-color: rgb(255, 255, 255);
  color: rgb(23, 104, 201);
  -moz-box-align: center;
  align-items: center;
  border-radius: 1000px;
  border-style: solid;
  border-width: 2px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: "Amazon Ember", Helvetica, sans-serif;
  font-weight: 500;
  -moz-box-pack: center;
  justify-content: center;
  min-height: 36px;
  outline: currentcolor none medium;
  position: relative;
  text-align: center;
  user-select: none;
  white-space: nowrap;
}

.custom-file-upload:hover {
  background-color: #f1f4f7;
}
